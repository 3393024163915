import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { EGAnalytics, analyticEvents } from '../../../../services/analytics'
import EmailBox from '../../../EmailBox'

import './styles.scss'

const Hero = props => {
  const data = props.data

  const handleSubmit = () => {
    EGAnalytics.track(analyticEvents.CTA_INTERACTION, {
      page: 'Product page - voice',
      section: 'Hero',
      ctaLabel: data.cta
    })
  }
  const heroImage = _.get(data, 'heroImage.file.url')
  return (
    <div className='eg-voice-hero'>
      <div className='container'>
        <div className='content'>
          <div className='subheader2'>{ data.subheader2 }</div>
          <h1 className='title'>{ data.header }</h1>
          <p className='description'>
            { _.get(data, 'subheader.subheader') }
          </p>
          <EmailBox cta={data.cta} onSubmit={ handleSubmit } section='Hero' />
        </div>
        <div className='image'>
          <img src={heroImage} alt='' />
        </div>
      </div>
    </div>
  )
}

Hero.propTypes = {
  data: PropTypes.object
}

export default Hero
