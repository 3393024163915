import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const HowItWorks = props => {
  const data = props.data
  return (
    <div className='eg-voice-hiw'>
      <div className='dashed-horizontal' />
      <div className='inner'>
        <div className='dashed-horizontal' />
        <div className='dashed-vertical' />
        {
          data.map((item, index) => (
            <div className='card' key={ index }>
              <div className='image'>
                <img src={ item.image.file.url } alt='' />
              </div>
              <div className='title'>{ item.title }</div>
              <div className='description'>{ item.paragraph.paragraph }</div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

HowItWorks.propTypes = {
  data: PropTypes.array
}

export default HowItWorks
