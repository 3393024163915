import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'

import MainLayout from '../components/MainLayout'
import { Hero, HowItWorks, Features } from '../components/Products/Voice'
import Demo from '../components/Demo'
import { EGAnalytics, analyticEvents } from '../services/analytics'

const scrollHandler = () => {
  if (!window.contentViewed) {
    if ((window.innerHeight + document.documentElement.scrollTop) >= document.body.scrollHeight) {
      EGAnalytics.track(analyticEvents.VOICE_PAGE_CONTENT_VIEWED)
      window.contentViewed = true
    }
  }
}

export default (props) => {
  const contentType = _.get(props, 'data.allContentfulHomeV3.edges').find(c => {
    return c.node.contentful_id === process.env.GATSBY_CONTENTFUL_ID_VOICE
  })
  const data = contentType.node

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.contentViewed = false
      window.onscroll = scrollHandler
    }
    EGAnalytics.track(analyticEvents.VOICE_PAGE_VIEWED)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  return (
    <MainLayout data={ data }>
      <Hero data={ data.hero } />
      <HowItWorks data={ data.howItWorks } />
      <Features features={ data.productFeatures } />
      <Demo data={data.getDemoCta}/>
    </MainLayout>
  )
}

export const pageQuery = graphql`
  query ProductVoiceMatadata {
    allContentfulHomeV3 {
      edges {
        node {
          title
          contentful_id
          createdAt
          hero {
            cta
            header
            subheader {
              subheader
            }
            subheader2
            heroImage {
              file {
                url
              }
            }
          }
          productFeatures {
            title
            subHeader
            image { file { url } }
            description { description }
          }
          howItWorks {
            image { file { url } }
            title
            paragraph {
              paragraph
            }
          }
          getDemoCta {
            title
            subHeader
            cta
          }
        }
      }
    }
  }
`
