import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import FeatureDotted from './FeatureDotted'

import './styles.scss'

const Features = props => {
  const { features } = props

  return (
    <section className='eg-product-voice-features'>
      <div className='inner'>
        {
          features.map((feature, index) => {
            const reverseClass = index % 2 ? 'reverse' : ''
            const image = _.get(feature, 'image.file.url')

            return (
              <FeatureDotted className={ `feature ${reverseClass}` } key={ index } index={ index }>
                <div className='content'>
                  <h2 className='title'>{ feature.title }</h2>
                  <h4 className='subheader'>{ feature.subHeader }</h4>
                  <p className='description'>
                    { feature.description.description }
                  </p>
                </div>
                <div className='image'>
                  <img src={ image } alt='feature 1' />
                </div>
              </FeatureDotted>
            )
          })
        }
      </div>
    </section>
  )
}

Features.propTypes = {
  features: PropTypes.array
}

export default Features
