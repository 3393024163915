import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const FeatureDotted = props => {
  const { className = '', children, index = 0 } = props

  const renderFirst = () => {
    return (
      <>
        <div className='vertical first-1' />
        <div className='dot first' />
      </>
    )
  }

  const renderReverse = () => {
    return (
      <>
        <div className='corner bottom-right' />
        <div className='horizontal top' />
        <div className='corner top-left' />
        <div className='vertical' />
        <div className='dot reverse' />
      </>
    )
  }

  const renderDefault = () => {
    return (
      <>
        <div className='corner bottom-left' />
        <div className='horizontal top' />
        <div className='corner top-right' />
        <div className='vertical' />
        <div className='dot default' />
      </>
    )
  }

  let first = null
  let reverse = null
  let def = null

  if (index === 0) {
    first = renderFirst()
  } else if (className.indexOf('reverse') !== -1) {
    reverse = renderReverse()
  } else {
    def = renderDefault()
  }


  return (
    <article className={ `eg-voice-feature-dotted ${className}` }>
      { first }
      { reverse }
      { def }
      { children }
    </article>
  )
}

FeatureDotted.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  index: PropTypes.number
}

export default FeatureDotted
